import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import BlogList from "./BlogList.js";
import { getState, setState } from "./stateManager.js";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";

// Constants
const ANIMATION_DELAY = 500;
const DATE_FORMAT = {
  weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

// Types
const CHANNEL_TYPES = {
  BLOGLIST: "bloglist",
  GAME: "game",
  PDF: "pdf",
};

// Image array for channel definitions
const channels = [
  {
    type: "pdf",
    url: "project_mag.png",
    text: "",
    channel: "project_mag.pdf",
    summary: "assets/pdf/project_mag.pdf",
  },
  {
    type: "bloglist",
    url: "camera_channel.jpg",
    text: "Camera Hacking",
    channel: "camera",
    summary: "This project involves reverse engineering a cheap IoT camera, while also being on the hunt for bugs.",
  },
  {
    type: "bloglist",
    url: "action_cam_channel.png",
    text: "Action Camera Hacking",
    channel: "body_cam",
    summary: "Why on Earth does an action camera need a WiFi hotspot?!?",
  },
  {
    type: "bloglist",
    url: "travel_router_channel.png",
    text: "Travel Router Hacking",
    channel: "travel_router",
    summary: "This channel is dedicated to hacking cheap travel routers.",
  },
  {
    type: "bloglist",
    url: "router_channel.jpg",
    text: "Router Hacking",
    channel: "router",
    summary: "In this project, I purchase a cheap router off of Aliexpress with the aim of remote code execution over LAN.",
  },
  {
    type: "bloglist",
    url: "keyboard.jpeg",
    text: "Single Articles",
    channel: "other",
    summary:
      "This channel is for single articles from varying topics that I can't justify an entire channel for!",
  },
  {
    type: "game",
    url: "vaporracer.png",
    text: "VaporRacer",
    channel: "vaporracer",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  },
  {
    type: "bloglist",
    url: "empty.jpg",
    text: "",
    channel: "",
    summary: "",
  }
];

// Utility functions
const formatDate = (date) => {
  const day = DATE_FORMAT.weekdays[date.getDay()];
  return `${day} ${date.getDate()}/${date.getMonth() + 1}`;
};

const formatTime = (date) => {
  return date.toLocaleTimeString('en-US', { 
    hour12: false, 
    hour: '2-digit', 
    minute: '2-digit' 
  });
};

// Components
const ChannelGridItem = ({ selected, image, onSelect }) => {
  const getChannelText = () => {
    if (image.type === CHANNEL_TYPES.GAME) {
      return `${image.text} 🕹️`;
    }
    return image.text;
  };

  return (
    <div
      className={`channel-grid-item ${selected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <img 
        src={`/assets/channel_previews/${image.url}`} 
        alt={image.text || "Channel preview"} 
      />
      <div className="text">{getChannelText()}</div>
    </div>
  );
};

const Grid = ({ onChannelClick }) => {
  return (
    <div className="channel-grid">
      {channels.map((channel, index) => (
        <ChannelGridItem
          key={index}
          selected={false}
          image={channel}
          onSelect={(event) => onChannelClick(event, channel.channel)}
        />
      ))}
    </div>
  );
};

const NavigationButton = ({ type, onClick }) => (
  <div className={`${type}_button_container`}>
    <div className={`${type}_button`} onClick={onClick} />
  </div>
);

const Border = () => {
  const navigate = useNavigate();

  return (
    <div className="navigation_border">
      <div className="navigation">
        <NavigationButton 
          type="wii" 
          onClick={() => navigate("/mii")} 
        />
        <NavigationButton 
          type="msg" 
          onClick={() => navigate("/contact")} 
        />
      </div>
    </div>
);
};

const TimeDisplay = ({ type }) => {
  const [current, setCurrent] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      const date = new Date();
      setCurrent(type === 'date' ? formatDate(date) : formatTime(date));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [type]);

  return <div className={type}>{current}</div>;
};

const AboutMe = () => {
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const markdown = await import("./about-me.md");
        const response = await fetch(markdown.default);
        const content = await response.text();
        setContent(content);
      } catch (error) {
        console.error("Error fetching Markdown content:", error);
      }
    };

    fetchMarkdownContent();
  }, []);

  const handleBackButton = () => {
    const container = document.querySelector(".slide-up");
    container.classList.add("slide-down");
    setTimeout(() => navigate("/"), ANIMATION_DELAY);
  };

  return (
    <div className="about-me-overlay">
      <div className="about-me-element-container">
        <div className="slide-up">
          <div className="back-button" onClick={handleBackButton} />
          <div className="about-me-background">
            <div className="about-me-element-container">
              <div className="about-me-title">👾 About Me</div>
              <div className="about-me-markdown">
                <Markdown>{content}</Markdown>
              </div>
            </div>
          </div>
          <div className="mii-text" />
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    const container = document.querySelector(".slide-up");
    container.classList.add("slide-down");
    setTimeout(() => navigate("/"), ANIMATION_DELAY);
  };

  return (
    <div className="about-me-overlay">
      <div className="slide-up">
        <div className="back-button" onClick={handleBackButton} />
        <div className="about-me-background" />
        <div className="mii-text" />
        <div className="contact-me-title">☎️ Contact Me</div>
        <div className="contact-grid-wrapper">
          <div className="contact-grid">
            <a href="https://www.github.com/lr-m/" className="github-button"> </a>
            <a href="mailto:luke.mills.35@outlook.com" className="email-button"> </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlogInterface = ({ setState, channel }) => {
  const navigate = useNavigate();

  const handleWiiMenuClick = () => {
    setState(getState() - 1);
    
    if (getState() === 0) {
      document.querySelector(".overlay").classList.add("fade-out-slow");
      document.querySelector(".zoom_in_animation").classList.add("zoom-out-animation");
      setTimeout(() => navigate("/"), ANIMATION_DELAY);
    } else if (getState() === 1) {
      const markdown = document.querySelector(".markdown-content");
      if (markdown) {
        markdown.classList.add("fade-out-slow");
      }
      setTimeout(() => {
        const overlay = document.querySelector(".bloglist_overlay");
        overlay.classList.remove("fade-out-slow");
        navigate(`/${channel}`);
        overlay.classList.add("fade-in");
      }, ANIMATION_DELAY);
    }
  };

  return <div className="wii_menu_button" onClick={handleWiiMenuClick} />;
};

const AppContent = () => {
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Luke's Blog";
    
    const handleNavigation = (event) => {
      if (!event.state) return;
      
      if (window.location.href.endsWith(".md")) {
        setState(2);
        return;
      }
      
      channels.some(channel => {
        if (window.location.href.endsWith(channel.channel)) {
          setState(1);
          const overlay = document.querySelector(".bloglist_overlay");
          if (overlay) {
            overlay.classList.remove("fade-out-slow");
            overlay.classList.add("fade-in");
          }
          return true;
        }
        return false;
      });
    };

    window.addEventListener("popstate", handleNavigation);
    return () => window.removeEventListener("popstate", handleNavigation);
  }, []);

  const handleChannelClick = (event, channel) => {
    const { width, height, left, top } = event.currentTarget.getBoundingClientRect();
    setClickPosition({
      x: (left + width / 2) - window.innerWidth / 2,
      y: (top + height / 2) - window.innerHeight / 2
    });
    setState(1);
    navigate(`/${channel}`);
  };

  return (
    <div style={{
      "--click_x": `${clickPosition.x}px`,
      "--click_y": `${clickPosition.y}px`,
    }}>
      <Grid onChannelClick={handleChannelClick} />
      
      <Routes>
      <Route path="/mii" element={<AboutMe />} />
      <Route path="/contact" element={<Contact />} />

        {channels.map(channel => (
          <Route
            basename={process.env.PUBLIC_URL}
            key={channel.channel}
            path={`/${channel.channel}/*`}
            element={
              <ChannelOverlay
                channel={channel}
                clickPosition={clickPosition}
              />
            }
          />
        ))}
        
        <Route path="/" element={<div />} />
      </Routes>

      <Border />
      <TimeDisplay type="date" />
      <TimeDisplay type="time" />
    </div>
  );
};

const ChannelOverlay = ({ channel, clickPosition }) => (
  <div className="overlay">
    <div className="zoom_in_animation">
      <BlogList
        type={channel.type}
        name={channel.text}
        channel={channel.channel}
        summary={channel.summary}
        backgroundImage={`/assets/channel_previews/${channel.url}`}
      />
      <BlogInterface setState={setState} channel={channel.channel} />
      {window.location.href.endsWith(channel.channel) &&
        setState(1)}
      {window.location.href.replace(/#.*/, "").endsWith(".md") &&
        setState(2)}
    </div>
  </div>
);

const App = () => (
  <BrowserRouter>
    <AppContent />
  </BrowserRouter>
);

export default App;
import React, { useEffect, useState } from "react";
import BlogPostRender from "./BlogPost.js";
import { getState, setState } from "./stateManager.js";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";

// Utility functions remain the same
const formatDate = (date) => {
  if (!date) return "";
  return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
};

const extractChannelFromFilename = (filename) => {
  return filename.split("-")[0];
};

const extractDateFromFilename = (filename) => {
  const dateRegex = /\d{4}-\d{2}-\d{2}/;
  const match = filename.match(dateRegex);
  
  if (match) {
    const [year, month, day] = match[0].split("-");
    return new Date(year, month - 1, day);
  }
  return null;
};

const countWords = (content) => {
  const contentWithoutCode = content
    .replace(/```[\s\S]*?```/g, '')
    .replace(/`[^`\n]+`/g, '')
    .replace(/<[^>]*>/g, '');
    
  const words = contentWithoutCode.match(/\b[\w']+\b/g);
  return words ? words.length : 0;
};

// Updated PDFViewer component using react-pdf-viewer
const PDFViewer = ({ pdfUrl, onClose }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div className="pdf-overlay" onClick={(e) => {
      if (e.target.className === 'pdf-overlay') {
        onClose();
      }
    }}>
      <div className="pdf-modal" onClick={e => e.stopPropagation()}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      </div>
    </div>
  );
};

// BlogPostGridElement remains the same
const BlogPostGridElement = ({ blogPost, onSelect }) => {
  return (
    <div className="blog-post" onClick={() => onSelect(blogPost)}>
      <h2 className="blog-title">{blogPost.title}</h2>
      <div className="blog-details">
        <div className="blog-meta">
          {blogPost.date && (
            <span className="blog-date">{formatDate(blogPost.date)}</span>
          )}
          <span className="blog-word-count">{blogPost.wordCount} words</span>
        </div>
        <p className="blog-excerpt">{blogPost.excerpt}</p>
        {blogPost.tags.length > 0 && (
          <div className="blog-tags">
            <ul className="tag-grid">
              {blogPost.tags.map((tag, index) => (
                <li key={index} className="tag">
                  {tag}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

// parseBlogPost function remains the same
const parseBlogPost = async (markdown, filename, channel) => {
  const response = await fetch(markdown.default);
  const content = await response.text();
  const frontMatterEndIndex = content.indexOf("---", 4);
  const frontMatter = content.slice(4, frontMatterEndIndex).trim();
  const markdownContent = content
    .slice(frontMatterEndIndex)
    .trim()
    .replace("---", "")
    .trim();

  const frontMatterLines = frontMatter.split("\n");

  const parsedData = {
    excerpt: "",
    title: "",
    tagline: "",
    tags: [],
    windowGradientStart: "#9c1c9c",
    windowGradientEnd: "#ff55ff",
    windowBorder: "#9c1c9c",
    minimizeButton: "#ffcc29",
    maximizeButton: "#49dcdc",
    closeButton: "#b967ff",
  };

  let isTagSection = false;

  for (const line of frontMatterLines) {
    const colonIndex = line.indexOf(":");
    if (colonIndex !== -1) {
      const key = line.slice(0, colonIndex).trim();
      const value = line.slice(colonIndex + 1).trim().replace(/"/g, "");

      if (key === "tags") {
        isTagSection = true;
        continue;
      }

      if (key in parsedData) {
        parsedData[key] = value;
      }
    } else if (isTagSection) {
      if (line === "" || line.includes(":") || line.includes("---")) {
        isTagSection = false;
      } else {
        const tags = line.trim().split("-").map(tag => tag.trim());
        parsedData.tags.push(...tags.filter(tag => tag !== ""));
      }
    }
  }

  return {
    ...parsedData,
    path: filename,
    content: markdownContent,
    channel: extractChannelFromFilename(filename),
    date: extractDateFromFilename(filename),
    filename,
    wordCount: countWords(markdownContent),
  };
};

// Main BlogList Component
const BlogList = ({ type, name, channel, summary, backgroundImage }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogPosts = async () => {
      if (type !== "bloglist") return;

      const blogPostPaths = require.context("./blogs", false);
      const blogPostPromises = blogPostPaths.keys()
        .filter(path => path.slice(2).startsWith(channel))
        .map(path => import(`./blogs/${path.slice(2)}`).then(markdown => 
          parseBlogPost(markdown, path.slice(2), channel)
        ));

      const posts = await Promise.all(blogPostPromises);
      setBlogPosts(posts.sort((a, b) => b.date - a.date));
    };

    fetchBlogPosts();
  }, [channel, type]);

  const handleBlogPostSelect = (blogPost) => {
    setState(getState() + 1);
    const overlay = document.querySelector(".bloglist_overlay");
    overlay.classList.add("fade-out-slow");
    overlay.classList.remove("fade-in");

    setTimeout(() => {
      const cleanPath = blogPost.filename.replace(/^[^-]+-[\d]{4}-[\d]{2}-[\d]{2}-/, "");
      navigate(`/${blogPost.channel}/${cleanPath}`);
    }, 300);
  };

  if (type === "pdf") {
    return (
      <div className="blogList_container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="bloglist_overlay">
          {getState() === 1 && (
            <>
              <h1 className="blogList_title">{channel}</h1>
              <h1 className="blogList_summary">{summary}</h1>
            </>
          )}
        </div>
        <style>
          {`
            .pdf-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.75);
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1000;
              padding: 2rem;
              cursor: pointer;
            }

            .pdf-modal {
              position: relative;
              width: 90%;
              max-width: 1200px;
              height: 90vh;
              background: white;
              border-radius: 12px;
              overflow: hidden;
              box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
              cursor: default;
            }
            
            .rpv-core__viewer {
              width: 100%;
              height: 100%;
            }

            @media (max-width: 768px) {
              .pdf-overlay {
                padding: 1rem;
              }
              
              .pdf-modal {
                width: 80vw;
                height: 80vh;
              }
            }
          `}
        </style>
        <PDFViewer 
          pdfUrl={summary} 
          onClose={() => navigate(-1)}
        />
      </div>
    );
  }

  if (type === "game") {
    return (
      <div className="blogList_container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="game_container">
          <iframe
            src="./VaporRacer.html"
            className="borderless-iframe"
            title="VaporRacer"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="blogList_container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="bloglist_overlay">
        {getState() === 1 && (
          <>
            <h1 className="blogList_title">{name}</h1>
            <h1 className="blogList_summary">{summary}</h1>
            <div className="blogPost_grid">
              {blogPosts.map((blogPost, index) => (
                <BlogPostGridElement
                  key={index}
                  blogPost={blogPost}
                  onSelect={handleBlogPostSelect}
                />
              ))}
            </div>
          </>
        )}
      </div>

      <Routes>
        {blogPosts.map((blogPost, index) => (
          <Route
            basename={process.env.PUBLIC_URL}
            key={index}
            path={`/${blogPost.filename.replace(/^[^-]+-[\d]{4}-[\d]{2}-[\d]{2}-/, "")}`}
            element={<BlogPostRender blogPost={blogPost} />}
          />
        ))}
      </Routes>
    </div>
  );
};

export default BlogList;
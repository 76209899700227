import Markdown from "markdown-to-jsx";
import SyntaxHighlighter from "react-syntax-highlighter";
import { qtcreatorDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import "./Home.css";
import "./fonts.css";
import "./Channel.css";
import "./Animation.css";
import "./AboutMe.css";
import "./BlogPost.css";
import React, { useState, useRef, useEffect } from 'react';

// For centering the table elements
const TableElement = (props) => {
  const centeredStyle = {
    textAlign: "center", // Horizontally center the content
    verticalAlign: "middle", // Vertically center the content
    paddingLeft: "1vh",
    paddingRight: "1vh",
    fontSize: "1.75vh"
  };

  return <td style={centeredStyle}>{props.children}</td>;
};

const TableHeader = (props) => {
  const centeredStyle = {
    textAlign: "center", // Horizontally center the content
    verticalAlign: "middle", // Vertically center the content
    paddingLeft: "1vh",
    paddingRight: "1vh",
    background: "rgba(50, 50, 50, 0.5)",
    fontSize: "2.25vh"
  };

  return <td style={centeredStyle}>{props.children}</td>;
};

// For centering the table in markdown
const CenteredTable = (props) => {
  const centeredStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={centeredStyle}>
      <table>{props.children}</table>
    </div>
  );
};

// This is used for centering images in the markdown blog
const CustomImage = ({ 
  alt, 
  src, 
  title, 
  windowGradientStart,
  windowGradientEnd,
  windowBorder,
  minimizeButton,
  maximizeButton,
  closeButton
}) => {
  const getFilename = (url) => {
    const cleanUrl = url.split('?')[0];
    const parts = cleanUrl.split('/');
    const filename = parts[parts.length - 1];
    return decodeURIComponent(filename);
  };
  
  const filename = getFilename(src);
  
  const windowStyle = {
    background: '#ffffff3f',
    border: `1px solid ${windowBorder}`,
    borderRadius: '8px 8px 0 0',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    display: 'inline-block',
    overflow: 'hidden'
  };
  
  const titleBarStyle = {
    background: `linear-gradient(to bottom, ${windowGradientStart}, ${windowGradientEnd})`,
    color: 'white',
    fontFamily: "'Tahoma', sans-serif",
    fontSize: '1.25vh',
    fontWeight: 'bold',
    padding: '2px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  };
  
  const buttonBaseStyle = {
    width: '1.25vh',
    height: '1.25vh',
    marginLeft: '2px',
    border: '1px solid #fff',
    borderRadius: '0.5vh'
  };

  return (
    <div className="winxp-window-container">
      <div style={windowStyle}>
        <div style={titleBarStyle}>
          <div className="winxp-title">
            <img src="/favicon.ico" alt="favicon" className="winxp-favicon" />
            <span className="winxp-filename">{filename}</span>
          </div>
          <div className="winxp-buttons">
            <span style={{
              ...buttonBaseStyle,
              background: minimizeButton
            }}></span>
            <span style={{
              ...buttonBaseStyle,
              background: maximizeButton
            }}></span>
            <span style={{
              ...buttonBaseStyle,
              background: closeButton
            }}></span>
          </div>
        </div>
        <div className="winxp-content">
          <img
            alt={alt}
            src={src}
            title={title}
          />
        </div>
      </div>
    </div>
  );
};

// This is for syntax highlighting, for some reason it is a bit broken so I modified the markdown code type definition slightly
const CodeBlock = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const codeRef = useRef(null);
  
  const lines = children.split('\n');
  const language = lines[0].startsWith('-') ? lines[0].slice(1).trim() : 'text';
  const code = lines[0].startsWith('-') ? lines.slice(1).join('\n') : children;
  
  const isLong = lines.length > 10;

  useEffect(() => {
    if (codeRef.current) {
      setIsScrollable(codeRef.current.scrollHeight > 200);
    }
  }, [code]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className={`code-block ${isLong ? 'long-code' : ''} ${isScrollable ? 'scrollable' : ''}`}>
      {isLong && (
        <button className="code-toggle" onClick={toggleExpand}>
          {isExpanded ? 'Collapse Code' : 'Expand Code'}
        </button>
      )}
      <div className={`code-content ${isExpanded ? 'expanded' : ''}`} ref={codeRef}>
        <SyntaxHighlighter
          language={language}
          wrapLongLines={true}
          style={qtcreatorDark}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

const WinampTOC = ({ markdownContent }) => {
  const [toc, setToc] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const WINAMP_GREEN = '#00FF00';
  const WINAMP_YELLOW = '#f8f2b9';
  const WINAMP_YELLOW_2 = '#d5c075';

  const styles = {
    container: {
      isolation: 'isolate',
      fontFamily: 'Arial, sans-serif',
      fontSize: '0.875em',
      lineHeight: '1.1',
      backgroundColor: '#000',
      color: WINAMP_GREEN,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    titleBar: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.25em',
      background: 'linear-gradient(90deg, #1c1b2b, #383555, #1c1b2b, #383555, #1c1b2b)',
      gap: '0.625em',
      height: '1.1em',
      paddingBottom: '.1em'
    },
    yellowBarsGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.05em',
      flex: 1,
      height: '100%',
      justifyContent: 'center'
    },
    yellowBarBig: {
      height: '0.15em',
      backgroundColor: WINAMP_YELLOW,
      borderRadius: '0.125em'
    },
    yellowBarSmall: {
      height: '0.1em',
      backgroundColor: WINAMP_YELLOW_2,
      borderRadius: '0.125em'
    },
    titleText: {
      color: '#bbb',
      fontWeight: 'bold',
      fontSize: '.5em',
      padding: '0',
      whiteSpace: 'nowrap'
    },
    playlist: {
      backgroundColor: '#000',
      padding: '0.1em',
      flexGrow: 1,
      overflowY: 'auto',
      overflowX: 'hidden',
      border: '0.4em solid',
      borderImage: 'linear-gradient(90deg, #1c1b2b, #383555, #1c1b2b, #383555, #1c1b2b) 1',
      outline: '0.125em solid #6f6f8a',
      outlineOffset: '-0.4em',
      boxShadow: '0 0 0 0.125em #73768c',
    },
    playlistItem: {
      fontSize: '.75em',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0.125em',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      textDecoration: 'none'
    },
    itemNumber: {
      width: '1.875em',
      textAlign: 'left',
      marginRight: '0.25em'
    },
    itemTitle: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    wordCount: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
      width: '2.5em',
      textAlign: 'right'
    }
  };

  const createId = (text) => {
    if (!text) return '';
    
    const cleanText = text.toString().trim();
    
    return cleanText
      .toLowerCase()
      // Replace & or &amp; with -- directly
      .replace(/&amp;|&/g, '--')
      // Then handle other special characters
      .replace(/[^a-z0-9\s--]/g, '')
      // Replace spaces with hyphens
      .replace(/\s+/g, '-')
      // Clean up any triple or more dashes
      .replace(/---+/g, '--')
      // Remove hyphens from start and end
      .replace(/^-+|-+$/g, '');
  };

  const countWords = (content) => {
    const contentWithoutCode = content.replace(/```[\s\S]*?```/g, '');
    const contentWithoutInlineCode = contentWithoutCode.replace(/`[^`\n]+`/g, '');
    const contentWithoutHtml = contentWithoutInlineCode.replace(/<[^>]*>/g, '');
    const words = contentWithoutHtml.match(/\b[\w']+\b/g);
    return words ? words.length : 0;
  };

  const countWordsInSection = (content, startIndex, level) => {
    const lines = content.split('\n');
    let sectionContent = [];
    let i = startIndex + 1;
    
    while (i < lines.length) {
      const line = lines[i];
      if (line.match(new RegExp(`^#{1,${level}}\\s`))) {
        break;
      }
      sectionContent.push(line);
      i++;
    }
    
    return countWords(sectionContent.join('\n'));
  };

  useEffect(() => {
    function generateTOC() {
      try {
        const lines = markdownContent.split('\n');
        const headers = [];
        let totalWords = countWords(markdownContent);
        let insideCodeBlock = false;

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          
          // Check for code block markers
          if (line.trim().startsWith('```')) {
            insideCodeBlock = !insideCodeBlock;
            continue;
          }

          // Only process headers if we're not inside a code block
          if (!insideCodeBlock) {
            const headerMatch = line.match(/^(#+)\s+(.*)/);
            
            if (headerMatch) {
              const level = headerMatch[1].length;
              const text = headerMatch[2];
              
              if (level < 4) {
                const words = countWordsInSection(lines.join('\n'), i, level);
                const id = createId(text);
                headers.push({ level, text, words, id });
              }
            }
          }
        }

        const tocItems = headers.map((header, index) => {
          const isSelected = selectedIndex === index + 1;
          const indent = (header.level - 1) * 2;

          return (
            <a
              key={`${index}-${header.text}`}
              href={`#${header.id}`}
              style={{
                ...styles.playlistItem,
                backgroundColor: isSelected ? '#0000c1' : 'transparent',
                color: isSelected ? '#ffffff' : WINAMP_GREEN,
                fontStyle: 'normal'
              }}
              onMouseOver={(e) => {
                if (!isSelected) {
                  e.currentTarget.style.backgroundColor = '#202020';
                  e.currentTarget.style.color = '#ffffff';
                }
              }}
              onMouseOut={(e) => {
                if (!isSelected) {
                  e.currentTarget.style.backgroundColor = 'transparent';
                  e.currentTarget.style.color = WINAMP_GREEN;
                }
              }}
              onClick={(e) => {
                setSelectedIndex(index + 1);
                e.preventDefault();
                const element = document.getElementById(header.id);
                element?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <span style={styles.itemNumber}>{index + 1}.</span>
              <span style={{
                ...styles.itemTitle,
                paddingLeft: `${indent}em`
              }}>{header.text}</span>
              <span style={styles.wordCount}>{header.words}</span>
            </a>
          );
        });
        
        setToc({ items: tocItems, totalWords });
      } catch (error) {
        console.error("Error generating TOC", error);
      }
    }

    generateTOC();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markdownContent, selectedIndex]);

  return (
    <div style={styles.container}>
      <div style={styles.titleBar}>
        <div style={styles.yellowBarsGroup}>
          <div style={styles.yellowBarBig} />
          <div style={styles.yellowBarSmall} />
        </div>
        
        <span style={styles.titleText}>TABLE OF CONTENTS</span>
        
        <div style={styles.yellowBarsGroup}>
          <div style={styles.yellowBarBig} />
          <div style={styles.yellowBarSmall} />
        </div>
      </div>
      
      <div style={styles.playlist}>
        {toc?.items}
      </div>
    </div>
  );
};

const BlogPostRender = ({ blogPost }) => {
  const [wordCount, setWordCount] = useState(0);

  const formattedDate = blogPost.date
    ? `${blogPost.date.getDate()}-${
        blogPost.date.getMonth() + 1
      }-${blogPost.date.getFullYear()}`
    : "";

  useEffect(() => {
    const countWords = (content) => {
      // Remove code blocks
      const contentWithoutCode = content.replace(/```[\s\S]*?```/g, '');
      
      // Remove inline code
      const contentWithoutInlineCode = contentWithoutCode.replace(/`[^`\n]+`/g, '');

      // Remove HTML tags
      const contentWithoutHtml = contentWithoutInlineCode.replace(/<[^>]*>/g, '');

      // Count words
      const words = contentWithoutHtml.match(/\b[\w']+\b/g);
      return words ? words.length : 0;
    };

    setWordCount(countWords(blogPost.content));
  }, [blogPost.content]);

  useEffect(() => {
    function adjustColumnHeight() {
      const leftCell = document.querySelector(".summary-container");
      const rightCell = document.querySelector(".right-cell");

      if (leftCell && rightCell) {
        rightCell.style.height = leftCell.offsetHeight + "px";
      }
    }

    adjustColumnHeight();
    window.addEventListener("resize", adjustColumnHeight);

    return () => {
      window.removeEventListener("resize", adjustColumnHeight);
    };
  }, []);

  return (
    <div className="fade-in-blogpost">
      <div className="markdown-content">
        <div className="blog-title-selected">{blogPost.title}</div>
        <div className="horizontal-line" />
        <div className="grid-container">
          <div className="grid-item left-cell">
            <div className="summary-container">
              <div className="blog-tagline-selected">{blogPost.tagline}</div>
              {blogPost.tags.length > 0 && (
                <div className="blog-post-tags">
                  <ul className="blog-post-tag-grid">
                    {blogPost.tags.map((tag, index) => (
                      <li key={index} className="blog-post-tag">
                        {tag}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="blog-word-count-centered">Word count: {wordCount}</div>
            </div>
          </div>
          <div className="grid-item right-cell">
            <WinampTOC markdownContent={blogPost.content} />
          </div>
        </div>

        <div className="horizontal-line" />

        <Markdown
          options={{
            overrides: {
              img: {
                component: (props) => (
                  <CustomImage 
                    {...props}
                    windowGradientStart={blogPost.windowGradientStart}
                    windowGradientEnd={blogPost.windowGradientEnd}
                    windowBorder={blogPost.windowBorder}
                    minimizeButton={blogPost.minimizeButton}
                    maximizeButton={blogPost.maximizeButton}
                    closeButton={blogPost.closeButton}
                  />
                ),
              },
              code: {
                component: CodeBlock,
              },
              td: {
                component: TableElement,
              },
              th: {
                component: TableHeader,
              },
              table: {
                component: CenteredTable,
              },
            },
          }}
        >
          {blogPost.content}
        </Markdown>
        <div className="horizontal-line"></div>
        <div className="date-selected">Last updated: {formattedDate}</div>
      </div>
    </div>
  );
};

export default BlogPostRender;
